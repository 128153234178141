const dropItems = {
  contactUs: [
      "General Inquiry",
      "Booking Request for Black Nerd Rises",
      "Convention Appearance Request",
      "Feedback and Appreciation",
      "Content Suggestions",
      "Merchandise Inquiry",
      "Questions About My Order",
      "Support or Technical Issue",
      "Partnership and Collaboration Opportunities",
      "Press and Media Contact",
      "Volunteer and Community Involvement",
      "Accessibility Questions / Issues"
    ],
};


export default dropItems