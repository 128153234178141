

const teamMembers = [
{
    uname: "Juggernaut",
    about: "OH YEEEAAAA IT'S DA JUGGERNAUT! AKA Bonecrusha, Destroyer of All, Earthquake. A brotha started this dream on the thought that; with how talented my associates and I were, we could take over this nerd thing. Anime, video games, comic books, movies, music, art, you name it, we got our hands out wantin a piece of it...and my hands are big. My favorite anime are Cowboy Bebop and Yu Yu Hakusho. My favorite games are Tekken and Persona. I bang Ludacris, FFDP, and Troyboi and I'm 6 ft 5 350+ of Bahamian African American excellence! Now Run. Tell. That!",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "aj pic.jpg"
},
{
    uname: "Liquid Nitrogen",
    about: "Fun fact- I was given this nickname bc I'm so cold. My favorite games are Disgaea and The Sims. My favorite Anime genres are horror and supernatural.",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "jaz pic 1.jpg"
},
{
    uname: "WokeTK",
    about: "It's WokeTK.......ASAP.....all those names, just know I talk shit, I win, and I can bullshit with the best of em",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "tim pic.jpg"
},
{
    uname: "Baby Satan",
    about: "Heya! Baby Satan here, I like indi games and horror anime. I read manga when I can, but honestly with how busy I am flipping on anime is just easier. Fav color is light blue, and right now I have been snacking on chocolate covered almonds. Catch me on Twitch for Saturday morning games and snacks! Dad jokes hit 7 out of 10 times. @me with your best dad joke in the general chat and I'll let you know if it hit",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "babysatan.jpg"
},
{
    uname: "Impulse D3lta",
    about: "Sup with it. Impulsed3lta komin in with the introduction.  Right now I am getting back into the anime and gaming shtick after a hiatus.  Got an Xbox a lil bit ago amd I'm slowly showing it some more use.  Got a good rpg? Maybe I'll try it.  In terms of anime show me something underrated or shorter and I'll watch it and see for myself.  Anything else let me know.  I'm a pretty good background charakter",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "zane pic 3.jpg"
},
{
    uname: "Eman4realz",
    about: "Yooo it's ya boi Eman4realz I'm in on most all manga anime but on the top of that list 'guilty crown' by far my favorite, but dont get mislead my the nerdy side I'm a hooper and I will dunk on you in any given opportunity. I'm on every platform except Xbox(mainly PC) league of legends what I be playing on there and Tekken is all time favorite game and nobody want that smoke 😎",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "eman.jpg"
},
{
    uname: "Madam Stormy",
    about: "I’m a gamer that loves anime! Awkward at first but I can talk after a while. My favorite game series is the Bioshock series. I play all of them every year around Halloween. I love horror games and movies. My favorite anime is SpyxFamily",
    tiktok: "tiktok.com/@madamstormy",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "madamstormy.jpg"
},
{
    uname: "xRiderrr",
    about: "What's up, I'm xRiderrr, a seasoned gamer, anime spectator, board game enthusiast, and TCG indulgent. I enjoy FPS and RPG games such as Apex, Elder Scrolls, and RB6 Siege. My favorite anime of all time (at this time) is Code Geass. I'm always trying to learn something new. I enjoy instigating, proper roast sessions, long walks on the beach, playing music, and fist-fighting geese.",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "derrick pic.jpg"
},
{
    uname: "Sifu HotSauce",
    about: "Anywhere from charmingly awkward to just awkward depending on the vibes. I’m still relatively new to anime and most of nerd culture (nerds used to get beat up in my formative years) but I’m owning tf out of it now. Still too fresh to call a favorite just yet but I fw One Punch, Demon Slayer, Hunter Hunter, Black Clover, damn er’thang it seem like right? Make me laugh.",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "sifupic.jpg"
},
{
    uname: "phatfu",
    about: "Phatfu = cats😸, plants🌵, coffee☕️. I’m the coolest crazy cat lady you’ll ever meet. ✌🏼Fairly new to the anime scene but have enjoyed: Cowboy Bebop, Attack on Titan, Samurai Shamploo, Hunter x Hunter, Demon Slayer and Black Clover. Love to play co-op games or anything Zelda-related but, I’m a sucker for a good jump-scare game. Come and vibe!",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "phatfu.jpg"
},
{
    uname: "Dark SkyWalker",
    about: "All right let's do this one last time.  I'm Sky walker 🙏 .  I'm into ANIME and MOVIEs.  Quickest way to become friends with me is to quote a movie. Need a 3rd on your Apex Squad, im your guy. Just know that you're not the Main character. I AM 😈 Want to talk metaphysics or philosophy, let's talk.  Negative vibes? We don't do that here.  Be respectful and remember....The force is always with you.",
    tiktok: "",
    facebook: "",
    twitter: "",
    instagram: "",
    thread: "",
    image: "jason pic.jpg"
},

];

export default teamMembers